.dark,
.light {
  padding: 0 1rem 1rem;
  min-height: 100vh;
}

.dark {
  color: var(--white);
  background-color: var(--black);
}
.light {
  color: var(--black);
  background-color: var(--white);
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
}

header {
  position: sticky;
  top: 0;
  right: 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
  backdrop-filter: blur(0.075rem);
}

header a {
  font-size: 250%;
  font-weight: bold;
}
header a span {
  color: var(--green);
}

header em {
  font-size: 85%;
}

main {
  max-width: 1320px;
  margin: auto;
}

footer {
  font-size: 90%;
  line-height: 1rem;
  position: fixed;
  inset: auto 1rem 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  color: var(--gray);
}
