.readme {
  position: relative;
  -webkit-animation-name: bottomsUp;
  -webkit-animation-duration: 0.75s;
  animation-name: bottomsUp;
  animation-duration: 0.75s;
  max-width: 968px;
  margin: auto;
}

.project-container {
  overflow-x: hidden;
}

article {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 0.25rem;
}

article h1,
article h2,
article h3,
article h4,
article h5,
article h6 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 0.25rem;
}

article h1 {
  font-size: 2em;
}

article h2 {
  padding-top: 2rem;
}

article h3 {
  padding-top: 0.5rem;
}

.repo-link {
  padding: 2rem 0;
}

.repo-link a,
article p a {
  color: inherit;
  opacity: 0.65;
}

.repo-link a:hover,
article p a:hover {
  opacity: 0.85;
}

article ul {
  list-style-position: inside;
  list-style-type: disc;
}

article ul li {
  padding-top: 0.5rem;
}

article ul li p:first-child {
  display: inline;
}

.anchor {
  margin-left: 0.5rem;
}

.anchor svg {
  fill: var(--light);
  width: 0.75rem;
  height: 0.75rem;
}
