.about.grid {
  grid-gap: 1rem;
}

.about .banner-img {
  background-image: url(../../assets/bondinho.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.25rem;
  height: 10rem;
}

@media only screen and (min-width: 798px) {
  .about .banner-img {
    height: 20rem;
  }
}
