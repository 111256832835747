.repo-list {
  padding: 0.5rem 0;
}

.repo-list span,
.repo-list .mural-title span {
  font-size: 80%;
}

.repo-list a {
  color: inherit;
  min-height: max-content;
  padding: 0.5rem;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  background-color: #88888830;
}

.repo-list a p {
  opacity: 0.8;
}

.repo-list a:hover {
  background-color: #88888860;
}

.repo-list a:hover p,
.repo-list a:hover h3 {
  opacity: 1;
}

.repo-list a p {
  font-size: 95%;
}

.repo-list a h3 {
  /* text-align: right; */
  opacity: 0.75;
}

.repo-list .repo-display {
  display: grid;
  grid-gap: 0.75rem;
  grid-template-rows: auto 1fr auto;
}

.repo-list .repo-display p {
  margin: 0;
}

.repo-list .info-display {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  align-items: end;
}

.repo-list .info-display span,
.repo-list .info-display p span {
  padding: 0;
}

.repo-list .languages-display,
.repo-list .date-display {
  margin: 0;
  display: grid;
}

.repo-list .languages-display {
  text-align: right;
}

.repo-list .date-display span {
  padding: 0;
}

@media only screen and (min-width: 1320px) {
  .repo-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .repo-list .mural-title {
    grid-column: 1 / 3;
  }
}

@media only screen and (min-width: 1920px) {
  .repo-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .repo-list .mural-title {
    grid-column: 1 / 4;
  }
}
