.lists-grid {
  position: relative;
  -webkit-animation-name: bottomsUp;
  -webkit-animation-duration: 0.75s;
  animation-name: bottomsUp;
  animation-duration: 0.75s;
}

.lists-grid .repo-list:nth-child(2) {
  grid-column-start: 2;
  grid-row: 1 / span 2;
}

@media only screen and (min-width: 880px) {
  .lists-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    align-items: start;
  }
}
