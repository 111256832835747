:root {
  --green: #b1ff00;
  --black: #21211e;
  --white: #fbfffe;
  --red: #a40606;
  --light: #d1d1d1;
  --gray: #585858;
  --grayer: #444444;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  outline: 0 !important;
  text-transform: lowercase;
}

a {
  color: inherit;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
  cursor: pointer !important;
}

ul {
  list-style: none;
}

pre {
  margin: 1rem 0;
  padding: 0.5rem;
  color: var(--green);
  background-color: var(--grayer);
  border-radius: 5px;
  overflow-x: scroll;
}

abbr[title] {
  text-decoration: none;
}

/* General fadein animation */
@-webkit-keyframes bottomsUp {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes bottomsUp {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

/* @media only screen and (max-width: 600px) {
  html {
    font-size: 100%;
  }
}
@media only screen and (min-width: 600px) {
  html {
    font-size: 105%;
  }
}
@media only screen and (min-width: 768px) {
  html {
    font-size: 110%;
  }
}
@media only screen and (min-width: 992px) {
  html {
    font-size: 115%;
  }
}
@media only screen and (min-width: 1200px) {
  html {
    font-size: 120%;
  }
}
@media only screen and (min-width: 1440px) {
  html {
    font-size: 125%;
  }
} */
