.loader,
.loader:before,
.loader:after {
  border-radius: 25%;
  width: 0.4rem;
  height: 0.4rem;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: roundLoad 1.8s infinite ease-in-out;
  animation: roundLoad 1.8s infinite ease-in-out;
}
.loader {
  font-size: 1rem;
  margin: 2rem auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -2rem;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 2rem;
}
@-webkit-keyframes roundLoad {
  0%,
  80%,
  100% {
    box-shadow: 0 1rem 0 -1.3rem;
  }
  40% {
    box-shadow: 0 1rem 0 0;
  }
}
@keyframes roundLoad {
  0%,
  80%,
  100% {
    box-shadow: 0 1rem 0 -1.3em;
  }
  40% {
    box-shadow: 0 1rem 0 0;
  }
}
